<template>
  <v-card>
    <v-card-title>
      Set delay
    </v-card-title>
    <v-card-text>
      <v-form ref="form">
        <ActionsDelay
          v-model="formData"
        ></ActionsDelay>
      </v-form>
      <v-row>
        <v-btn
          :loading="loading"
          @click="SaveModeDelay"
        >
          Save mode
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          :loading="loading"
          @click="UpdateDelay"
        >
          Save
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import SettingsApi from '@core/api/settings'
import { mapActions, mapState } from 'vuex'
import userStoreModule, { USER_MODULE_NAME } from '@/store/modules/userStoreModule'
import ActionsDelay from '@/components/ActionsDelay.vue'
import store from '@/store'

export default {
  components: {
    ActionsDelay,
  },
  props: {
    delayData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      DefaultFormData: {
        account_delay: 15,
        batch_delay: 1,
        batch_size: 5,
      },
      formData: {},
    }
  },
  computed: {
    ...mapState({
      user(state) {
        return state[USER_MODULE_NAME]?.user
      },
    }),
  },
  watch: {
    delayData() {
      this.formData = {
        account_delay: this.delayData.account_delay,
        batch_delay: this.delayData.batch_delay,
        batch_size: this.delayData.batch_size,
      }
    },
  },
  mounted() {
    // Register module
    if (!store.hasModule(USER_MODULE_NAME)) {
      store.registerModule(USER_MODULE_NAME, userStoreModule)
    }
  },
  methods: {
    ...mapActions({
      setDelay(dispatch, payload) {
        return dispatch(`${USER_MODULE_NAME}/setDelay`, payload)
      },
    }),
    async UpdateDelay() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          await this.setDelay(this.formData)
          this.$message.success('Delay updated successfully')
        } catch (err) {
          this.$message.error(err)
        } finally {
          this.loading = false
        }
      }
    },
    async SaveModeDelay() {
      try {
        this.loading = true
        await SettingsApi.Delay.setDelay(this.DefaultFormData)
        this.formData = { ...this.DefaultFormData }
        this.$message.success('Delay updated successfully')
      } catch (err) {
        this.$message.error(err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style>
</style>
