<template>
  <v-autocomplete
    v-model="localValue"
    deletable-chips
    :loading="loading"
    :disabled="disabled"
    :items="localItems"
    :search-input.sync="search"
    cache-items
    :multiple="multiple"
    chips
    hide-details="auto"
    hide-selected
    :rules="rules"
    label="Select proxy"
    clearable
    @update:search-input="pageReset"
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="remove(data.item.value)"
      >
        {{ data.item.text || data.item.description }}
      </v-chip>
    </template>
    <template
      v-slot:item="{item,on, attrs}"
    >
      <v-list-item
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ item.text || item.description }}
          </v-list-item-title>
          <v-list-item-subtitle v-show="item.text">
            {{ item.description }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:append-item="">
      <div v-intersect="endIntersect">
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import ProxyApi from '@core/api/proxy'
import { required } from '@core/utils/validation'
import { mapState } from 'vuex'
import uniq from 'lodash/uniq'
import { PROXY_MODULE_NAME } from '@/store/modules/proxyStoreModule'

export default {
  props: {
    disabled: Boolean,
    multiple: Boolean,
    rules: { type: Array, default: () => [] },
    value: {
      type: [Number, Array],
      default: null,
    },
    accountProxies: {
      type: Object,
      default: null,
    },
    isFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      items: null,
      search: null,
      page: 1,
      total: 0,
    }
  },
  computed: {
    ...mapState({
      stateProxies(state) {
        return state[PROXY_MODULE_NAME]?.proxies?.map(({ value, name, id }) => ({ text: name, value: id, description: value }))
      },
    }),
    localItems() {
      if (this.isFilter) {
        return [{ text: 'No proxy', value: 'null' }].concat(this.items || this.stateProxies || [])
      }
      if (this.accountProxies && this.items) {
        return uniq(this.items.concat([this.accountProxies]))
      }

      return this.items || this.stateProxies
    },
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    search(val) {
      this.tryFetchProxies(val)
    },
  },
  mounted() {
    if (!this.stateProxies?.length) {
      this.tryFetchProxies()
    }
  },
  methods: {
    required,
    async tryFetchProxies(search) {
      this.loading = true
      await ProxyApi.fetchProxies({ search, limit: 20, page: this.page }).then(({ data }) => {
        this.items = data.items.map(({ value, name, id }) => ({ text: name, value: id, description: value }))
        this.total = data.total
      })
      this.loading = false
    },
    remove(item) {
      const index = this.localValue.indexOf(item)
      if (index >= 0) this.localValue.splice(index, 1)
    },
    async  endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting && this.page * 20 < this.total) {
        this.page += 1
        const { data } = await ProxyApi.fetchProxies({ limit: 20, page: this.page })
        this.items.push(...data.items.map(({ name, id }) => ({ text: name, value: id })))
      }
    },
    pageReset() {
      this.page = 1
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
