<template>
  <v-card>
    <v-card-title>Twitter</v-card-title>
    <v-card-text>
      <v-form
        ref="form"
        class="mb-8"
      >
        <label for="user_key">Twitter token (<a href="https://developer.twitter.com/en/docs/twitter-api/getting-started/getting-access-to-the-twitter-api">api twitter token</a>)</label>
        <div class="d-flex">
          <v-text-field
            id="user_key"
            v-model="twitterToken"
            :disabled="loading"
          />
          <v-tooltip
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-show="status === 'active'"
                class="ml-4"
                color="success"
                v-bind="attrs"
                v-on="on"
              >
                {{ icons.mdiCheckCircleOutline }}
              </v-icon>
              <v-icon
                v-show="status === 'locked'"
                v-bind="attrs"
                class="ml-4"
                color="warning"
                v-on="on"
              >
                {{ icons.mdiLockOutline }}
              </v-icon>
            </template>
            <span>{{ status }}</span>
          </v-tooltip>
        </div>
        <SelectProxy
          v-model="proxy"
          label="Proxy"
          placeholder="Select proxy"
          hide-details
        />
      </v-form>
      <v-row>
        <v-btn
          :loading="loading"
          :disabled="twitterToken.length===0"
          @click="deleteToken"
        >
          Delete
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          :loading="loading"
          @click="updateToken"
        >
          Save
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import TwitterApi from '@core/api/twitter'
import { mdiCheckCircleOutline, mdiLockOutline } from '@mdi/js'
import SelectProxy from '../proxy/SelectProxy.vue'

export default {
  components: {
    SelectProxy,
  },
  data() {
    return {
      twitterToken: '',
      loading: false,
      proxy: [],
      status: null,
      icons: {
        mdiCheckCircleOutline,
        mdiLockOutline,
      },
    }
  },
  mounted() {
    this.tryGetToken()
  },
  methods: {
    async tryGetToken() {
      try {
        this.loading = true
        const { data } = await TwitterApi.Token.getToken()
        this.twitterToken = data.token
        this.proxy = data.proxy.id
        this.status = data.status
        this.loading = false
      } catch {
        this.reset()
      }
    },
    async updateToken() {
      try {
        this.loading = true
        await TwitterApi.Token.setToken({ token: this.twitterToken, proxy: this.proxy })
        await this.tryGetToken()
        this.loading = false
        this.$message.success('Token updated successfully')
      } catch (err) {
        this.loading = false
        this.$message.error(err)
      }
    },
    async deleteToken() {
      try {
        this.loading = true
        await TwitterApi.Token.deleteToken()
        this.reset()
        this.loading = false
        this.$message.success('Token deleted successfully')
      } catch (err) {
        this.loading = false
        this.$message.error(err)
      }
    },
    reset() {
      this.loading = false
      this.twitterToken = ''
      this.proxy = []
      this.status = null
    },
  },
}
</script>
