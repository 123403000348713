<template>
  <div>
    <v-row>
      <v-col>
        <label for="batch_delay">
          Batch Delay
          <span>(min.)</span>
        </label>
        <v-text-field
          id="batch_delay"
          v-model.number="formData.batch_delay"
          type="number"
          :rules="[simpleRequired,isMore(1),integerValidator]"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <label for="account_delay">
          Account Delay
          <span>(sec.)</span>
        </label>
        <v-text-field
          id="account_delay"
          v-model.number="formData.account_delay"
          type="number"
          :rules="[simpleRequired,isMore(1),integerValidator,maxMultiplyValue(formData.batch_size,900)]"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <label for="batch_size">
          Batch size
        </label>
        <v-text-field
          id="batch_size"
          v-model.number="formData.batch_size"
          type="number"
          :rules="[simpleRequired,isMore(1),integerValidator,maximumValue(30),maxMultiplyValue(formData.account_delay,900)]"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  maximumValue, maxMultiplyValue, simpleRequired, isMore, integerValidator,
} from '@core/utils/validation'
import { mapState } from 'vuex'
import store from '@/store'
import userStoreModule, { USER_MODULE_NAME } from '@/store/modules/userStoreModule'

export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  computed: {
    formData: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    ...mapState({
      user(state) {
        return state[USER_MODULE_NAME]?.user
      },
    }),
  },
  mounted() {
    // Register module
    if (!store.hasModule(USER_MODULE_NAME)) {
      store.registerModule(USER_MODULE_NAME, userStoreModule)
    }
    this.formData = {
      account_delay: this.user.account_delay,
      batch_delay: this.user.batch_delay,
      batch_size: this.user.batch_size,
    }
  },
  methods: {
    maximumValue,
    maxMultiplyValue,
    simpleRequired,
    isMore,
    integerValidator,
  },
}
</script>
