<template>
  <v-card>
    <HCaptchaSection
      :capsolver-api-key="capsolverApiKey"
      @captchaSaved="$emit('saved')"
    ></HCaptchaSection>
    <v-card-title>
      Image captcha solver
    </v-card-title>
    <v-card-text>
      <v-form ref="form">
        <label for="user_key">Captcha API key(<a
          v-if="formData.solver ==='two_captcha'"
          href="https://2captcha.com/"
        >2captcha.com</a>
          <a
            v-else
            href="https://anti-captcha.com/"
          >anti-captcha</a>)</label>

        <v-text-field
          id="user_key"
          v-model="localUserKey"
          :disabled="loading"
          :rules="[required]"
        />
        <v-radio-group v-model="solver">
          <v-radio
            label="Two captcha"
            value="two_captcha"
          ></v-radio>
          <v-radio
            label="Anti captcha"
            value="anti_captcha"
          ></v-radio>
        </v-radio-group>
      </v-form>
      <v-row>
        <v-spacer />
        <v-btn
          color="primary"
          :loading="loading"
          @click="localUpdateCaptcha"
        >
          Save
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { required } from '@core/utils/validation'
import GlobalNotification from '@core/utils/globalNotification'
import HCaptchaSection from '@/components/settings/HCaptchaSection.vue'
import store from '@/store'
import userStoreModule, { USER_MODULE_NAME } from '@/store/modules/userStoreModule'

export default {
  components: { HCaptchaSection },
  props: {
    twocaptchaUserKey: {
      type: String,
      default: '',
    },
    anticaptchaApiKey: {
      type: String,
      default: '',
    },
    capsolverApiKey: {
      type: String,
      default: '',
    },
    captchaSolver: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      GlobalNotification,
      solver: '',
      formData: { key: '', solver: '' },
    }
  },
  computed: {
    localUserKey: {
      get() {
        return this.formData.key || this.userKey
      },
      set(value) {
        this.formData.key = value
      },
    },
  },
  watch: {
    captchaSolver() {
      this.solver = this.captchaSolver
      this.formData.key = this.solver === 'two_captcha' ? this.twocaptchaUserKey : this.anticaptchaApiKey
    },
    solver() {
      this.formData.solver = this.solver
      this.formData.key = this.solver === 'two_captcha' ? this.twocaptchaUserKey : this.anticaptchaApiKey
    },
  },
  mounted() {
    // Register module
    if (!store.hasModule(USER_MODULE_NAME)) {
      store.registerModule(USER_MODULE_NAME, userStoreModule)
    }
  },
  methods: {
    required,
    ...mapActions({
      setCaptcha(dispatch, payload) {
        return dispatch(`${USER_MODULE_NAME}/setNewCaptcha`, payload)
      },
    }),
    async localUpdateCaptcha() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          await this.setCaptcha({
            ...this.formData,
            key: this.localUserKey,
            solver_for: 'image',
          })

          this.$message.success('Captcha updated successfully')
        } catch (err) {
          this.$message.error(err)
        }
        this.loading = false
      }
    },
  },
}
</script>

<style>
</style>
