<template>
  <div>
    <CaptchaSection
      :captcha-solver="userData.image_solver"
      :twocaptcha-user-key="userData.twocaptcha_api_key"
      :anticaptcha-api-key="userData.anticaptcha_api_key"
      :capsolver-api-key="userData.capsolver_api_key"
    />
    <TwitterSection class="mt-10"></TwitterSection>
    <DelaySection
      :delay-data="delayData"
      class="mt-10"
    ></DelaySection>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CaptchaSection from '@/components/settings/CaptchaSection.vue'
import TwitterSection from '@/components/settings/TwitterSection.vue'
import DelaySection from '@/components/settings/DelaySection.vue'
import userStoreModule, { USER_MODULE_NAME } from '@/store/modules/userStoreModule'
import store from '@/store'

export default {
  components: {
    CaptchaSection, TwitterSection, DelaySection,
  },
  data() {
    return {
      loading: false,
      userData: {},
    }
  },
  computed: {
    ...mapState({
      user(state) {
        return state[USER_MODULE_NAME]?.user
      },
    }),
    delayData() {
      return {
        account_delay: this.userData.account_delay,
        batch_delay: this.userData.batch_delay,
        batch_size: this.userData.batch_size,
      }
    },
  },
  watch: {
    user() {
      this.userData = {
        ...this.user,
      }
    },
  },
  mounted() {
    if (!store.hasModule(USER_MODULE_NAME)) {
      store.registerModule(USER_MODULE_NAME, userStoreModule)
    }

    this.userData = {
      ...this.user,
    }
  },
}
</script>

<style>
</style>
