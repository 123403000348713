<template>
  <div>
    <v-card-title>hCaptcha solver</v-card-title>
    <v-card-text>
      <v-form ref="form">
        <label for="user_key">CapSolver (<a href="https://dashboard.capsolver.com/passport/register?inviteCode=XVploYORlj3e">capsolver</a>)</label>
        <v-text-field
          id="user_key"
          v-model="key"
          :disabled="loading"
          :rules="[required]"
        />
      </v-form>
      <v-row>
        <v-spacer />
        <v-btn
          color="primary"
          :loading="loading"
          @click="saveCaptcha"
        >
          Save
        </v-btn>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { required } from '@core/utils/validation'
import store from '@/store'
import userStoreModule, { USER_MODULE_NAME } from '@/store/modules/userStoreModule'

export default {
  props: {
    capsolverApiKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      key: '',
      required,
    }
  },
  watch: {
    capsolverApiKey() {
      this.key = this.capsolverApiKey
    },
  },
  mounted() {
    // Register module
    if (!store.hasModule(USER_MODULE_NAME)) {
      store.registerModule(USER_MODULE_NAME, userStoreModule)
    }
  },
  methods: {
    ...mapActions({
      setCaptcha(dispatch, payload) {
        return dispatch(`${USER_MODULE_NAME}/setNewCaptcha`, payload)
      },
    }),
    async saveCaptcha() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          await this.setCaptcha({
            key: this.key,
            solver: 'cap_solver',
            solver_for: 'hcaptcha',
          })
          this.$message.success('Captcha updated successfully')
          this.$emit('captchaSaved')
        } catch (err) {
          this.$message.error(err)
        }
        this.loading = false
      }
    },
  },
}
</script>
