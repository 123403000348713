import ProxyApi from '@core/api/proxy'

export const PROXY_MODULE_NAME = 'app-proxy'

export default {
  namespaced: true,
  state: {
    proxies: [],
  },
  getters: {},
  mutations: {
    SET_PROXIES(state, proxies) {
      state.proxies = proxies
    },

    EDIT_PROXY(state, value) {
      const editedProxyIdx = state.proxies.findIndex(p => p.id === value.id)

      if (~editedProxyIdx) {
        state.proxies.splice(editedProxyIdx, 1, value)

        return value
      }

      return null
    },

    REMOVE_PROXY(state, proxy) {
      const deletedProxyIdx = state.proxies.findIndex(p => p.id === proxy.id)

      //
      if (~deletedProxyIdx) {
        return state.proxies.splice(deletedProxyIdx, 1)
      }

      return null
    },
  },
  actions: {
    fetchProxies({ commit }, params) {
      return ProxyApi.fetchProxies(params)
        .then(response => {
          commit('SET_PROXIES', response.data.items)

          return response
        })
    },

    addProxy(ctx, { proxy }) {
      return ProxyApi.addProxy({ proxy })
    },

    addProxies(ctx, { proxies }) {
      return ProxyApi.addProxies({ proxies })
    },

    updateProxy(ctx, { proxy }) {
      return ProxyApi.updateProxy({ proxy })
    },

    removeProxy(ctx, proxy) {
      return ProxyApi.removeProxy(proxy)
    },
  },
}
