import axios from '@axios'

export default {
  checkProxy: ({ proxies }) => axios.post('/proxy/check', { proxies }),

  fetchProxies: params => axios
    .get('/proxy', {
      params,
    }),

  addProxy: ({ proxy }) => axios
    .post('/proxy', proxy),

  addProxies: ({ proxies }) => axios
    .post('/proxy/mass-create', { proxies }),

  updateProxy: ({ proxy }) => axios
    .patch(`/proxy/${proxy.id}`, proxy),

  changeIp: ({ proxy }) => axios
    .post(`/proxy/${proxy.id}/change_ip`),

  removeMany: proxies => axios
    .delete('/proxy/mass-delete', { data: { proxies } }),

  removeProxy: proxy => axios
    .delete(`/proxy/${proxy.id}`),
}
